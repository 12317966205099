<template>
  <section class="container">
    <app-bar title="Monitoring MCU Instansi" previousLink="/hasil" />

    <div v-if="!kodeRekanan || !monitoring" class="row">
      <div class="px-3 pb-5 pb-md-0 mb-md-3">
        <span class="fw-700 fs-18">Kode Rekanan</span>
          <p class="fw-400 color-neutral-600 mb-0">Masukkan kode rekanan anda untuk <br> melihat laporan hasil monitoring</p>

          <input type="text" class="form-control form-capsule mt-4" :class="{'has-input': kodeRekanan}" placeholder="Kode Rekanan" v-model="kodeRekanan">
          <button class="btn btn-primary btn-login w-100 mt-3" :disabled="!kodeRekanan" @click="prepareGetMonitoring">Cari</button>

          <!-- <div class="w-100 my-3 px-0" v-if="!monitoring && pernahCari">
            <div class="card-info p-3 d-flex flex-row align-items-center bg-danger-50 border-0 color-danger-500">
              <i class="ri-information-line fs-24"></i>
              <p class="mb-0 px-2 fs-12 fw-400">Pastikan anda sudah mengisi data nomor KTP dengan benar.</p>
            </div>
          </div> -->

          <Empty class="mt-5" v-if="!monitoring && pernahCari && !loading.monitoring"
            :title="'Kode rekanan tidak ditemukan'"
            :message="'Maaf kode yang anda masukkan tidak terdapat laporan hasil monitoring'"
          />
          <Loading class="mt-5" v-else-if="loading.monitoring" />
      </div>
    </div>

    <Empty v-else-if="monitoring.detail.length === 0" :message="'Saat ini belum ada monitoring untuk ditampilkan.'" />
    <div v-else class="row">
      <div class="col-12 col-md-12 p-0">
        <div class="table-responsive">
          <table class="table table-monitoring table-striped w-100">
            <thead class="fs-12 lh-22 fw-700 color-neutral-700 bg-neutral-200 text-center">
              <tr v-for="header in monitoring.header" :key="header">
                <th scope="col" v-for="(value, key) in header" :key="value" class="text-center">
                  <div :style="{ width: (key === 'th_namapemeriksaan' ? '130px' : 'max-content') }" class="mx-auto">{{ value
                  }}</div>
                </th>
              </tr>
            </thead>
            <tbody class="fs-12 lh-22 color-neutral-700">
              <tr v-for="detail in monitoring.detail" :key="detail">
                <td v-for="(value, key) in detail" :key="key">
                  <div :class="{ 'text-center': key !== 'th_namapemeriksaan' }" style="word-break: break-word;"
                    :style="{ width: (key === 'th_namapemeriksaan' ? '130px' : '200px') }">
                    {{ value }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from '@/components/utility/Loading'
import Empty from '@/components/utility/Empty'
import { getMonitoring, loading } from '@/core/mcu'
import { user } from '@/core/auth'
import { showNotif } from '@/core/utility'
import { ref } from 'vue'
import AppBar from '@/components/AppBar.vue'

export default {
  components: { Loading, Empty, AppBar },
  setup () {
    const kodeRekanan = ref('')
    const monitoring = ref('')
    const pernahCari = ref(false)

    const prepareGetMonitoring = async () => {
      try {
        pernahCari.value = true
        const result = await getMonitoring({
          kode_rekanan: kodeRekanan.value,
          pas_id: user.value.pas_id
        })

        if (!result.status) {
          showNotif({
            status: 'error',
            message: status.message
          })
        } else {
          monitoring.value = result
        }
      } catch (error) {
        console.error('error dari Monitoring prepareGetMonitoring', error)
      }
    }

    return {
      loading,
      prepareGetMonitoring,
      kodeRekanan,
      pernahCari,
      monitoring
    }
  }
}
</script>

<style lang="scss" scoped>
#patient-monitoring {
  margin-top: 60px;
}

.card-patient {
  .doctor-info {
    border-radius: 11px;
  }

  .icon-wrapper {
    img {
      width: 14px;
      height: 14px;
    }
  }
}

.table-responsive {
  overflow-x: auto;

  .table-monitoring {

    th:first-child,
    td:first-child {
      position: sticky;
      left: 0px;
      padding-left: 20px;
    }

    th:first-child {
      background-color: #edf2f7;
    }

    td:first-child {
      background-color: #f7fafc;
    }
  }

  .table-monitoring> :not(caption)>*>* {
    border-bottom-width: 0px !important;
  }
}
</style>
